import {
  AboutUs,
  ActivateAccount,
  BorrowerPolicy,
  ContactUs,
  GeneralPolicy,
  Landing,
  LenderPolicy,
  TermsAndConditions,
} from 'features/misc';
import { useAuth } from 'lib/auth';
import { useRoutes } from 'react-router-dom';
import { protectedRoutes } from './protected';
import { publicRoutes } from './public';

export const AppRoutes = () => {
  const auth = useAuth();

  const commonRoutes = [
    { path: '/terms-and-conditions', element: <TermsAndConditions /> },
    { path: '/lender-policy', element: <LenderPolicy /> },
    { path: '/borrower-policy', element: <BorrowerPolicy /> },
    { path: '/general-policy', element: <GeneralPolicy /> },
  ];

  const routes = auth.user ? protectedRoutes : publicRoutes;

  const element = useRoutes([...routes, ...commonRoutes]);

  return <>{element}</>;
};
