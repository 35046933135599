import { lazyImport } from 'utils/lazyImport';
import { Navigate } from 'react-router';

const { AuthRoutes } = lazyImport(() => import('features/auth'), 'AuthRoutes');
const { LogsRoutes } = lazyImport(() => import('features/logs'), 'LogsRoutes');

export const publicRoutes = [
  {
    path: '/*',
    element: <AuthRoutes />,
  },
  { path: '/app/logs/*', element: <LogsRoutes /> },
  { path: '*', element: <Navigate to="." /> },
];
