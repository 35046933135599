import { Button } from 'components/Elements';
import { Form, InputField, PhoneInputField } from 'components/Form';
import { useAuth } from 'lib/auth';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import * as z from 'zod';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

export const LoginForm = ({ onSuccess }) => {
  const { login, isLoggingIn } = useAuth();

  const [phoneNumber, setPhoneNumber] = useState('');
  const [showPasswordInput, setShowPasswordInput] = useState(false);

  const navigate = useNavigate();

  return (
    <div>
      <Form
        onSubmit={async (values) => {
          values.phoneNumber = phoneNumber;

          await login(values);
          onSuccess();
        }}
        // schema={schema}
      >
        {({ register, formState }) => (
          <>
            <PhoneInput
              country={'ug'}
              value={phoneNumber}
              inputStyle={{
                width: '100%',
                height: '60px',
                fontSize: '1rem',
                border: '1px solid #e5e5e5',
                borderRadius: '8px',
              }}
              onChange={(phone) => setPhoneNumber(`+${phone}`)}
            />
            <InputField
              type="password"
              label="Password"
              passwordField={true}
              error={formState.errors['password']}
              registration={register('password')}
            />
            <div>
              <Button isLoading={isLoggingIn} type="submit" className="w-full">
                Sign in
              </Button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};

LoginForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};
