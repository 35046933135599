import { Button, Link } from 'components/Elements';
import { Head } from 'components/Head';
import PropTypes from 'prop-types';
import logo from 'assets/logo.svg';

export const Layout = ({ children, title }) => {
  return (
    <>
      <Head title={title} />

      <div className="mx-auto md:w-1/3 py-8 px-4 mt-24 sm:px-10">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="flex justify-center">
            <Link className="flex items-center text-white" to="/">
              <img className="h-10 w-auto" src={logo} alt="finablr" />
            </Link>
          </div>

          <h2 className="mt-3 text-center text-xl font-extrabold text-gray-900 dark:text-white">
            {title}
          </h2>
        </div>
        <div className="my-3">{children}</div>
        <div className="mt-4 text-xs text-center text-gray-500">
          Enabling Finance Limited © 2024 All rights reserved.
        </div>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};
