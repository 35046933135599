import logo from 'assets/logo.png';
import { Button } from 'components/Elements';
import { Head } from 'components/Head';
import { Logo, WebLayout } from 'components/Layout';
import { useAuth } from 'lib/auth';
import { useNavigate } from 'react-router';
import { Check } from 'react-feather';

export const Landing = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleStart = () => {
    if (user) {
      navigate('/app');
    } else {
      navigate('/auth/login');
    }
  };

  return (
    <WebLayout>
      <Head title="Home" description="EFL Monitor" />
      <div className="flex flex-col justify-center">
        <Logo />
        <div className="mt-12 text-lg text-center">Welcome to the Finablr Monitor System.</div>
        <div className="text-center">
          This system is used to monitor API requests sent to the finablr API and can only be
          accessed by authorized personnel.
        </div>
      </div>
      <div className="flex justify-center mt-8">
        <Button onClick={handleStart} className="mt-4">
          Get Started
        </Button>
      </div>
    </WebLayout>
  );
};
