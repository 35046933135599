import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

export const Tooltip = ({ content, children, className }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div className={clsx('relative inline-block', className)}>
      <div className="relative" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {children}
      </div>
      {showTooltip && (
        <div className="absolute w-[50vw] z-10 p-2 -ml-20 bg-gray-800 text-white text-sm rounded-md shadow-md">
          {content}
        </div>
      )}
    </div>
  );
};

Tooltip.propTypes = {
  content: PropTypes.node.isRequired, // Content of the tooltip
  children: PropTypes.node.isRequired, // Children that trigger the tooltip
  className: PropTypes.string, // Additional class names for styling
};
