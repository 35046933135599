import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { UserNavigation } from './components/UserNavigation';
import { useAuth } from 'lib/auth';
import { Logo } from './components/Logo';

export const MainLayout = ({ children }) => {
  const { user } = useAuth();
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000); // Update every second

    return () => clearInterval(intervalId);
  }, []); // Run only once on component mount

  return (
    <div className="h-screen flex overflow-hidden bg-white">
      <div className="bg-[#FAFAFA] flex flex-col w-0 flex-1 overflow-hidden">
        <div className="relative bg-white px-4 md:px-24 z-10 items-center flex-shrink-0 flex py-4 shadow">
          <Logo />
          <div className="px-10 text-xs text-gray-700 font-bold truncate overflow-hidden hidden md:block">
            <span className="text-[#50CAF3]">
              {user.basicInformation?.firstName} {user.basicInformation?.lastName}
            </span>{' '}
            | <span className="text-secondary-100">Finablr</span> Real Time Monitoring System
            <span className="ml-2">| Current Time: {currentTime.toLocaleTimeString()}</span>
          </div>
          <div className="flex-1 px-4 flex justify-end">
            <div className="ml-4 flex items-center md:ml-6">
              <UserNavigation />
            </div>
          </div>
        </div>
        <main className="flex-1 relative overflow-y-auto focus:outline-none">{children}</main>
      </div>
    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
